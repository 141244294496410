import { Component, ElementRef } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { environment } from '../../../../../environments/environment';
import { CmsService } from '@spartacus/core';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-faq-video-popup',
  templateUrl: './faq-video-popup.component.html',
  styleUrl: './faq-video-popup.component.scss',
})
export class FaqVideoPopupComponent {

  public componentData: any;
  videoUrl: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public component: CmsComponentData<any>,
    private elRef: ElementRef,
    private pageService: CmsService
  ) { }

  ngOnInit(): void {
    this.pageService.getComponentData('AccessRequestQ1VideoComponent')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        this.videoUrl = environment.siteUrls.getBackofficeUrl + data?.video?.url.slice('/medias');
      });
    this.component.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.componentData = data;
      });
  }

  ngAfterViewInit(): void {
    const modalElement = this.elRef.nativeElement.querySelector('#closeVideo');
    modalElement.addEventListener('hidden.bs.modal', () => { });
  }

  stopVideo(video) {
    video.pause();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
